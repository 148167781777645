import React, { useState, useEffect } from "react";

import {
  addPromotionalWorkshopCompletion,
  getPromotionalWorkshopCompletions
} from "api/db";

const Hacklytics = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [completions, setCompletions] = useState([]);

  useEffect(() => {
    getPromotionalWorkshopCompletions(snapshot => {
      let data = snapshot.val();
      const object = { ...data };
      setCompletions(existing => existing.concat(object));
    });
  }, []);

  return (
    <>
      <main className="relative mx-auto max-w-screen-xl md:flex">
        <div className="md:w-1/4">
          <div className="w-48 mx-auto mt-8 mx-4">
            <img
              className="flex-1 float-none mx-auto shadow-md p-4"
              src="/img/hacklytics.png"
            />
          </div>

          <div className="text-center mt-8">
            <div class="-space-x-4 mt-4 ">
              <img
                class="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                src="/img/cohort/erica_headshot.jpg"
                alt="Profile image"
              />
              <img
                class="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                src="/img/cohort/erin_headshot.png"
                alt="Profile image"
              />
              <img
                class="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                src="/img/cohort/varun_headshot1.jpg"
                alt="Profile image"
              />
              <img
                class="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                src="/img/cohort/nisreen_headshot.jpg"
                alt="Profile image"
              />
              <img
                class="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                src="/img/cohort/nori_headshot.png"
                alt="Profile image"
              />
              <img
                class="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                src="/img/cohort/liv_headshot.jpg"
                alt="Profile image"
              />
              <img
                class="relative z-10 inline object-cover w-10 h-10 border-2 border-white rounded-full"
                src="/img/cohort/oliver_headshot.jpg"
                alt="Profile image"
              />
            </div>
            <p className="mx-auto max-w-lg mt-3 text-2xl text-gray-700">
              Join our Discord community.
            </p>
            <span class="relative inline-flex mt-3">
              <span class="inline-flex items-center px-4 py-2 text-base leading-6 font-medium rounded-md text-gray-800 bg-gray-200 hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                100+ online now
              </span>
              <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
              </span>
            </span>
            <br />
            <div className="rounded-md shadow-sm relative inline-flex mt-3">
              <a target="_blank" href="https://discord.gg/8FwTpfN">
                <button class="inline-flex items-center px-4 py-2 border border-gray-100 text-base leading-6 font-medium rounded-md text-gray-800 bg-white hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                  Enter chat
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="md:w-2/4">
          <main className="mt-10 mx-auto max-w-screen-xl px-4">
            <div className="text-center">
              <div
                className="py-1 bg-teal-500 hover:bg-teal-400 items-center text-gray-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
                role="alert"
              >
                <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                  Workshop
                </span>
              </div>
            </div>
            <div className="text-center">
              <h2 className="text-2xl tracking-tight font-bold text-gray-900 ">
                Realtime Pose Estimator w/ ml5.js
              </h2>
              {/* <p className="text-gray-600 max-w-lg mx-auto">
              If you liked this workshop, check out our two-week{" "}
              <Link to="/courses/web-development">
                <a className="text-blue-600 underline">
                  web development course
                </a>
              </Link>{" "}
              starting on October 18th. You'll learn the fundamentals of HTML,
              CSS, JS by building three projects in a supoortive and accountable
              community.
            </p> */}
            </div>

            <section className="max-w-2xl mx-auto mt-4">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Name
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-100"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Website URL
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-500"
                    type="text"
                    placeholder="https://"
                    value={url}
                    onChange={e => {
                      setUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-center mb-4">
                <button
                  className="bg-yellow-600 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded mr-2"
                  onClick={() => {
                    if (name.length > 2 && url.length > 5) {
                      addPromotionalWorkshopCompletion(name, url, 0);
                      setName("");
                      setUrl("");
                    } else {
                      alert("Please fill out both fields.");
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </section>

            <div className="p-4 max-w-xl mx-auto">
              <div className="text-right">
                <b>{completions.length}</b> submissions
              </div>
              <div className="flex justify-center">
                <div className="">
                  {completions.map(completion => (
                    <div
                      className="w-full rounded overflow-hidden shadow px-16 py-8 m-4 text-center"
                      key={completion.url}
                    >
                      <p className="text-gray-900 text-xl">
                        <b>{completion.name}</b> built{" "}
                        <a
                          target="_blank"
                          href={completion.url}
                          className="text-blue-600 font-bold"
                        >
                          {completion.url}
                        </a>{" "}
                        &#8250;
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="md:w-1/4">
          <main className="mt-10 mx-auto max-w-screen-xl">
            <div className="text-center">
              <div
                className="py-1 bg-teal-500 hover:bg-teal-400 items-center text-gray-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
                role="alert"
              >
                <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                  Resources
                </span>
              </div>
            </div>
            <div className="ml-2">
              <h2 className="text-2xl tracking-tight font-bold text-gray-900 text-center">
                Projects & Resources
              </h2>
              <p className="font-bold p-1 py-4 underline">Beginner Projects</p>
              <a
                className="p-1 block text-gray-700 hover:text-teal-500 hover:underline"
                href="https://enlight.nyc/projects/ml5-seefood"
                target="_blank"
              >
                Build an Image Recognition Web App
              </a>
              <a
                className="p-1 block text-gray-700 hover:text-yellow-600 hover:underline"
                href="https://enlight.nyc/projects/backtester"
                target="_blank"
              >
                Build a Cryptocurrency Algorithm Backtester
              </a>
              <a
                className="p-1 block text-gray-700 hover:text-teal-500 hover:underline"
                href="https://enlight.nyc/projects/sentiment-analysis"
                target="_blank"
              >
                Sentiment Analysis
              </a>

              <p className="font-bold p-1 py-4 underline">
                Intermediate Projects
              </p>
              <a
                className="p-1 block text-gray-700 hover:text-yellow-600 hover:underline"
                href="https://enlight.nyc/projects/neural-network"
                target="_blank"
              >
                Build a Neural Network
              </a>
              <a
                className="p-1 block text-gray-700 hover:text-teal-500 hover:underline"
                href="https://enlight.nyc/projects/build-a-web-scraper"
                target="_blank"
              >
                Build a Web Scraper
              </a>
              <a
                className="p-1 block text-gray-700 hover:text-yellow-600 hover:underline"
                href="https://enlight.nyc/projects/stock-market-prediction"
                target="_blank"
              >
                Build a Stock Prediction Algorithm
              </a>
              <p className="font-bold p-1 py-4 underline">
                Datasets & project ideas
              </p>
              <ul>
                <li className="block py-2">
                  1. Recreate President Trump's Twitter account using Enlight's{" "}
                  <a
                    className="text-gray-700 hover:text-teal-500 hover:underline cursor-pointer"
                    href="https://enlight.nyc/projects/twitter-bot"
                    target="_blank"
                  >
                    Build a Twitter Bot project
                  </a>{" "}
                  and this
                  <a
                    className="text-gray-700 hover:text-yellow-600 hover:underline cursor-pointer"
                    href="https://www.kaggle.com/ayushggarg/all-trumps-twitter-insults-20152021"
                    target="_blank"
                  >
                    {" "}
                    dataset of Trump tweets{" "}
                  </a>
                  or run sentiment analysis!
                </li>

                <li className="block py-2">
                  2. Answer critical questions about COVID-19 using:
                  <a
                    className="text-gray-700 hover:text-teal-500 hover:underline cursor-pointer"
                    href="https://www.kaggle.com/gpreda/covid-world-vaccination-progress"
                    target="_blank"
                  >
                    {" "}
                    Vaccination progress data,{" "}
                  </a>
                  <a
                    className="text-gray-700 hover:text-yellow-600 hover:underline cursor-pointer"
                    href="https://www.kaggle.com/arashnic/covid19-case-surveillance-public-use-dataset"
                    target="_blank"
                  >
                    {" "}
                    Infection demographics data{" "}
                  </a>
                  and{" "}
                  <a
                    className="text-gray-700 hover:text-teal-500 hover:underline cursor-pointer"
                    href="https://www.kaggle.com/gauravduttakiit/covid-19?select=data"
                    target="_blank"
                  >
                    Infection/death rate data.
                  </a>
                </li>

                <li className="block py-2">
                  3. Build a Netflix recommender system using:
                  <br />
                  <a
                    className="text-gray-700 hover:text-yellow-600 hover:underline cursor-pointer"
                    href="https://www.kaggle.com/shivamb/netflix-shows"
                    target="_blank"
                  >
                    Netflix TV shows & movies{" "}
                  </a>
                  and
                  <a
                    className="text-gray-700 hover:text-teal-500 hover:underline cursor-pointer"
                    href="https://www.kaggle.com/stefanoleone992/imdb-extensive-dataset"
                    target="_blank"
                  >
                    {" "}
                    IMDb ratings.
                  </a>
                </li>
              </ul>
              <br />
              <p className="block py-2">
                For more datasets, visit
                <a
                  className="p-1 text-gray-700 hover:text-yellow-600 hover:underline cursor-pointer"
                  href="https://careerfoundry.com/en/blog/data-analytics/where-to-find-free-datasets/"
                  target="_blank"
                >
                  this blog on careerfoundry.
                </a>
              </p>
              <br />
              <p className="p-2 ftext-gray-900 mb-2">
                {" "}
                View more Enlight projects{" "}
                <a
                  className="underline hover:text-yellow-600 hover:underline cursor-pointer"
                  href="/projects"
                  target="_blank"
                >
                  here!
                </a>
              </p>
            </div>
          </main>
        </div>
      </main>
      <div className="bg-gray-100 p-4">
        <section className="mx-auto max-w-screen-xl px-4">
          <div className="p-4 mx-auto max-w-4xl mt-8 mb-4 text-center rounded">
            <h1 className="text-3xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
              After you're done with Hacklytics...
            </h1>
            <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-800 mt-2">
              Submit your hackathon project to Enlight
            </h1>
            <p className="mx-auto max-w-lg mt-4 text-xl text-gray-700">
              After you're done building, write a tutorial and share your
              project on Enlight, the best-in-class educational network to
              learn, build, and share programming projects.
            </p>

            <div className="md:flex flex-row text-gray-800 mt-4">
              <div className="flex-1 m-2">
                <b>Showcase your project. </b>
                Write a tutorial for your hackathon project and it will be
                approved by Enlight staff in less than 24 hours.
              </div>
              <div className="flex-1 m-2">
                <b>Gain exposure. </b>
                Want to reach more people? Projects on Enlight typically recieve
                thousands of views and are a great way to build up your
                portfolio.
              </div>
              <div className="flex-1 m-3">
                <b> Join the community. </b>
                Become a part of the Enlight team of contributors. Meet new
                people and work together to spread CS education.
              </div>
            </div>
            <div className="mt-4 sm:mt-4 sm:flex justify-center">
              <div className="rounded-md shadow">
                <a
                  href="/contribute/"
                  target="__blank"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                >
                  Learn more &#8250;
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Hacklytics;
